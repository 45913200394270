@import '../../../style/_variables';
@import '../../../style/_mixins';

.btn-scrollup{
    position: fixed;
    bottom: 3rem;
    right: 3rem;
    background-color: $main-color;
    color: $white;
    padding: 0.75rem 1rem;
    border-radius: 50%;
    border: none;
    &:hover{
        color: $text-color-3;
    }
}
.homepage{
    margin:0;
    padding:0;
    overflow-x: hidden;
}