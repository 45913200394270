@import '../../../style/variables';
@import '../../../style/_mixins';
.footer{
    padding: 100px 0 0;
    overflow: hidden;
    background-image: $footerbackground;
    color: $white;
    ul{
        padding: 0;
    }
    h4{
        margin-bottom: 2rem;
    }
    li{
        margin-bottom: 10px;
    }
    .footer__copyright{
        padding: 20px 0;
        margin: 40px 0 0 ;
        background-color: $black;
        text-align: center;
        overflow: hidden;
    }
    .btn-footer-form{
        background-color: $text-color-3;
        &:hover{
            background-color: $text-color-3;
            color: $main-color;
        }
    }
    .footer-form input , textarea{
        margin: 10px auto;
    }

}

@media (max-width:992px){
    .footer{
        text-align: center;
        .footer-offer , .footer-template , .footer-form{
            padding: 2rem 0;
        } 
        .footer-form{
            margin: auto;
            input , textarea{
                margin: 10px auto;
            }
        }
    }
}

@media (max-width:768px){
    .footer{
        text-align: center;
        .footer-offer , .footer-template , .footer-form{
            border-bottom: 1px solid $white;
            padding: 2rem 0;
        } 
        .footer-form input , textarea{
            margin: 10px auto;
        }
    }
}