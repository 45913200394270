@import '../../../style/variables';
@import '../../../style/_mixins';

.devices{
    @extend %section-padding;
    .title{
        @extend %section-title;
    }
    .sub-title{
        @extend %section-subtitle;
    }
}

@media (max-width:768px){
    .Devises__title{
        text-align: center;
    }
}
