@import "../../../style/variables";
@import "../../../style/_mixins";

.forms_btn {
    @include input_animation();
    @extend %formsButton;

    &:hover,
    &:focus,
    &:active {
        -webkit-box-shadow: $FormsBtnshadow;
        box-shadow: $FormsBtnshadow;
        @include scale_animation();
    }
}

.form_footer {
    border-radius: $getstartedBorder;
    background-color: $main-color;
}

.form {
    h2 {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        text-transform: uppercase;
        display: inline-block;
        margin: 40px 8px 10px 8px;
        color: $headercolor;
        border-bottom: 2px solid $form-underline;
    }
}

.wrapper {
    width: 100%;
    min-height: 80%;
    padding: 20px;
}

#formContent {
    -webkit-border-radius: $getstartedBorder;
    border-radius: $getstartedBorder;
    background: $white;
    padding: 30px;
    padding: 0px;
    width: 550px;
    -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
    text-align: center;
}

#formFooter {
    background-color: $main-color;
    border-radius: $getstartedBorder;
    padding: 25px;
    text-align: center;
    -webkit-border-radius: 0 0 10px 10px;
    border-radius: 0 0 10px 10px;
}

h2 {
    margin-top: 2rem;
    color: #0d0d0d;
    font-weight: bolder;
    border: none !important;
    /* border-bottom: 2px solid #5fbae9; */
}

.getstarted_btn {
    @include input_animation();
    @extend %getstartedButton;

    &:hover,
    &:focus,
    &:active {
        -webkit-box-shadow: $main-color;
        box-shadow: $main-color;
        @include scale_animation();
    }
}

input {
    background-color: #f6f6f6;
    border: none;
    color: #0d0d0d;
    font-size: 16px;
    margin: 5px;
    width: 70%;
    border: 2px solid #f6f6f6;
    @extend %input;
    @include input_animation();
}

// input:focus {
//     background-color: #fff;
//     border-bottom: 2px solid #fe8991;
// }

input:placeholder {
    color: $bg-color-3;
}

.icon:before {
    transform: scale(1) rotate(45deg);
}

.icon:after {
    transform: scale(1) rotate(-45deg);
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    opacity: 0;
    -webkit-animation: fadeIn ease-in 1;
    -moz-animation: fadeIn ease-in 1;
    animation: fadeIn ease-in 1;

    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;

    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    animation-duration: 1s;
}

.fadeIn.first {
    -webkit-animation-delay: 0.4s;
    -moz-animation-delay: 0.4s;
    animation-delay: 0.4s;
}
.fadeIn.second {
    -webkit-animation-delay: 0.6s;
    -moz-animation-delay: 0.6s;
    animation-delay: 0.6s;
}
.fadeIn.third {
    -webkit-animation-delay: 0.8s;
    -moz-animation-delay: 0.8s;
    animation-delay: 0.8s;
}

.fadeIn.fourth {
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    animation-delay: 1s;
}
.underlineHover:after {
    display: block;
    left: 0;
    bottom: -10px;
    width: 0;
    height: 2px;
    background-color: #56baed;
    content: "";
    transition: width 0.2s;
}

.underlineHover:hover {
    color: #0d0d0d;
}

.underlineHover:hover:after {
    width: 100%;
}

.alert-danger {
    padding: 7px;
    border-radius: 6px;
    width: 85%;
    margin: 10px auto;
}

.getStarted {
    align-items: center;
}
// ---------------------------------------
// add asss
.add {
    // color: #110330;
    border-radius: 20px;
    background-color: #feb3b2;
    color: $white;
    align-items: center;
}
.btns-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.navigation-btns {
    @include input_animation();

    &:hover,
    &:focus,
    &:active {
        -webkit-box-shadow: $main-color;
        box-shadow: $main-color;
        @include scale_animation();
    }
    color: #110330;
    padding: 0.8rem;
    border-radius: 20px;
    background-color: #feb3b2;
    position: relative;
}

.navigation-btns .tooltiptext {
    visibility: hidden;
    font-size: 12px;
    width: 200px;
    background-color: $black;
    color: $white;
    text-align: center;
    border-radius: 6px 6px 6px 0;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 60px;
}

.navigation-btns:hover .tooltiptext {
    visibility: visible;
}
.dropdown {
    position: relative;
    color: $white;
    /* padding: 2rem; */
    text-align: center;
    border-radius: 27px;
    background-color: $black;
    height: 3rem;

    .options {
        border-radius: 27px !important;
        text-align: center;
        height: 3rem;
    }
}

.add_delete {
    border-radius: 25px;
    padding: 15px 80px;
    background-color: $main-color;
    width: 8% !important;
    border: none;
    margin: 1rem 0rem;
}

.assignee_email {
    height: 3rem;
}

.save {
    font-size: 0.8rem;
}

.modal-dialog {
    max-width: 700px !important;
}

.assignee_body {
    justify-content: space-evenly;
}
