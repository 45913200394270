@import '../../../style/variables';
@import '../../../style/_mixins';
  
  .features__container {
    // height: 90vh;
    display: flex;
    flex-wrap: nowrap;
    margin: 0;
    -webkit-overflow-scrolling: touch;
    .panel {
    width: 900px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 1.5em;
    text-align: center;
    color: $white;
    position: relative;
    box-sizing: border-box;
    padding: 10px;
    }
  }
@media (max-width:768px){
  .features__container{
    display: none;
  }
}

  
  


  

  
  

  
  
