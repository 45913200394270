// vendors
$vendors: "-moz-",
"-webkit-",
"-o-",
"-ms-",
"";


$main-color: #654b9b;
$main-color-opacity: #654b9b20;
$text-color-1: #221934;
$text-color-2: #666;
$text-color-3:#fe8991;
$opacity-pink :#fe899138;
$bg-color-2: #eee;
$bg-color-3: #ccc;
$bg-color-4: gray;
$box-shadow: 0 .5rem 1.5rem rgba(0, 0, 0, .1);

$red:red;
$green:green;
// colors definitaions
$navBackground:#fff;
$formsbtncolr:#fe8991;
$formfootercolr:#654b9b;
$headercolor:#221934;
$form-underline:#45e3ff;
$subMenuBg:#1d1b31;
$sideBarBg:#11101d;

$counterBackground:linear-gradient(to right top, #6034c8, #654b9b, #9460de, #ab77e9, #c18ef4);
$footerbackground:radial-gradient(circle at 47% 14%,
    rgba(205, 205, 205, 0.04) 0%,
    rgba(205, 205, 205, 0.04) 43%,
    transparent 43%,
    transparent 100%),
radial-gradient(circle at 35% 12%,
    rgba(215, 215, 215, 0.04) 0%,
    rgba(215, 215, 215, 0.04) 4%,
    transparent 4%,
    transparent 100%),
radial-gradient(circle at 1% 35%,
    rgba(24, 24, 24, 0.04) 0%,
    rgba(24, 24, 24, 0.04) 37%,
    transparent 37%,
    transparent 100%),
radial-gradient(circle at 21% 1%,
    rgba(0, 0, 0, 0.04) 0%,
    rgba(0, 0, 0, 0.04) 26%,
    transparent 26%,
    transparent 100%),
radial-gradient(circle at 23% 82%,
    rgba(249, 249, 249, 0.04) 0%,
    rgba(249, 249, 249, 0.04) 60%,
    transparent 60%,
    transparent 100%),
radial-gradient(circle at 11% 54%,
    rgba(251, 251, 251, 0.04) 0%,
    rgba(251, 251, 251, 0.04) 23%,
    transparent 23%,
    transparent 100%),
radial-gradient(circle at 69% 68%,
    rgba(234, 234, 234, 0.04) 0%,
    rgba(234, 234, 234, 0.04) 10%,
    transparent 10%,
    transparent 100%),
linear-gradient(90deg, #745fab, #8071a8);
$primaryHex: #114685;
$primaryRGB: rgb(17, 70, 133);
$primaryDarkHex: #00274b;
$primaryDarkRGB: rgb(0, 39, 75);
$gradientStartColor: #019cfc;
$gradientEndColor: #004692;
$secondaryHex: #fbbe17;
$white: #fff;
$black:#000;

//sizes 
$FormsBorder :10px 10px 10px 10px;
$FormsBtnshadow :0 4px 30px 0 $text-color-3;
$FormsBorder :10px 10px 10px 10px;
// $getstartedButtonBackground: #7540f2;
$getstartedBorder:0px 40px;
// font family
$robotoFont: 'Roboto',
sans-serif;
$openSansFont: 'Open Sans',
sans-serif;

// images paths
$features_one: "../../../images/one.jpg";
$features_two: "../../../images/two.jpg";


