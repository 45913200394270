@import '../../../style/_variables';
@import '../../../style/_mixins';
.pricing{
    @extend %section-padding;
    text-align: center;
    .title{
        @extend %section-title;
    }
    .sub-title{
        @extend %section-subtitle;
    }
    Button{
        background:$navBackground;
        border-radius:25px;
        color: $black;
        font-size: 18px;
    }
    .priceBtns__items{
        margin:10px 30px;
        padding: 5px 30px;
        border: 1px solid $main-color;
        font-weight: 400;
        color: $black;
        &:hover , &.active{
            background-color: $main-color;
            border: 1px solid $main-color;
        }
    }
    .plansItems{
        margin-top:40px;
        justify-content:center;
        .featuer{
            color:$black;
            font-size: 16px;
            padding:5px 0 15px;
            border-bottom: 1px solid #ddd;
        }
        .plansItems_content{
            &:hover{
                transform: scale(1.1);
                cursor: pointer;
            }
            .cardData{
                text-align: center;
            }
            .cardPrice{
                text-decoration: none;
                font-size: 50px;
                font-weight: 700;
                color: $main-color;
            }
            .cardTitle{
                font-size: 22px;
                color:$text-color-3;
                font-weight: 700;
            }
            border: 1px solid #c6c9cf;
            border-radius: 30px 0 30px 0 ;
            box-shadow: 1px 1px 23px -6px rgba(139,139,139,0.69);
            -webkit-box-shadow: 1px 1px 23px -6px rgba(139,139,139,0.69);
            -moz-box-shadow: 1px 1px 23px -6px rgba(139,139,139,0.69);
        }
        .plan-btn{
            color: $main-color;
            border: 1px solid $main-color;
            &:hover{
                background-color: $main-color;
                color: $white;
            }
        }
    }
}