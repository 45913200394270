@import url('https://fonts.googleapis.com/css?family=Muli&display=swap');
@import '../../../../style/variables';
@import '../../../../style/mixins';
.close-btn{
    align-self: end;
    margin-right: 2rem;
}
.task-container {
	display: flex;
    position:relative;
    .task-header{
        border-radius: 30px;
        background-color: #2A265F;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
        max-width: 100%;
        margin: 20px;
        .task-header_sources{
            color: $white;
            padding: 30px;
            h6{
                opacity: 0.6;
                margin: 0;
                letter-spacing: 1px;
                text-transform: uppercase;
            }
            h4{
                letter-spacing: 1px;
                margin: 10px 0;
            }
        }
    }
    .task-info{
        padding: 30px;
	    width: 100%;
        .task-info_flex{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding: 10px 0;
            .task-info_status{
                color:#063c32;
            }
            .task_assignees{
                display: flex;
                span{
                    background-color: $main-color;
                    border-radius:50%;
                    height: 20px;
                    width: 20px;
                    display: flex;
                    padding: 15px;
                    color: $white;
                    justify-content: center;
                    align-items: center;
                    border: 0.25px solid $text-color-3;
                    margin: 0 1px
                };
            }
        }
    }
    .btn {
        background-color: #2A265F;
        border: 0;
        border-radius: 50px;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
        color: $white;
        font-size: 12px;
        padding: 12px 25px;
        letter-spacing: 1px;
    }
}
.floating-btn {
	border-radius: 26.5px;
	background-color: #001F61;
	border: 1px solid #001F61;
	box-shadow: 0 16px 22px -17px #03153B;
	color: $white;
	cursor: pointer;
	font-size: 16px;
	line-height: 20px;
	padding: 12px 20px;
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 999;
}

.floating-btn:hover {
	background-color: $white;
	color: #001F61;
}

.floating-btn:focus {
	outline: none;
}

.floating-text {
	background-color: #001F61;
	border-radius: 10px 10px 0 0;
	color: $white;
	font-family: 'Muli';
	padding: 7px 15px;
	position: fixed;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
	z-index: 998;
}

.floating-text a {
	color: #FF7500;
	text-decoration: none;
}

@media screen and (max-width: 480px) {

	.social-panel-container.visible {
		transform: translateX(0px);
	}
	
	.floating-btn {
		right: 10px;
	}
}