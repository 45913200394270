@import '../../../style/variables';
@import '../../../style/_mixins';
.loader_display{
    display: none;
}
.login {
    @extend %section-padding;

    a{
        color: $white;
        display: inline-block;
        text-decoration: none !important;
        font-weight: 400;
        &:hover{
            color: $text-color-3;
            text-decoration: none;
        }
    }

    .form {
        h2 {
            text-align: center;
            font-size: 25px;
            font-weight: 500;
            text-transform: uppercase;
            display: inline-block;
            margin: 2rem;
            color: $headercolor;
            border-bottom: 2px solid $form-underline;

        }
    }


    .wrapper {

        width: 100%;
        min-height: 80%;
        padding: 20px;
    }

    #formContent {
        -webkit-border-radius: $FormsBorder;
        border-radius: $FormsBorder;
        background: $white;
        padding: 30px;
        padding: 0px;
        -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
        box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
        text-align: center;
    }

    #formFooter {

        padding: 25px;
        text-align: center;
        -webkit-border-radius: 0 0 10px 10px;
        border-radius: 0 0 10px 10px;
        background-color: $main-color;
        a{
            color: $white;
            display: inline-block;
            text-decoration: none !important;
            font-weight: 400;
            &:hover{
                color: $text-color-3;
                text-decoration: none;
            }
        }
    }

    h2 {
        margin-top: 2rem;
        color: #0d0d0d;
        font-weight: bolder;
        border: none !important;
        @extend %fontMontserrat;
    }


    .forms_btn {
        @include input_animation();
        @extend %formsButton;

        &:hover,
        &:focus,
        &:active {
            -webkit-box-shadow: $FormsBtnshadow;
            box-shadow: $FormsBtnshadow;
            @include scale_animation();
        }
    }

    input {
        background-color: #f6f6f6;
        border: none;
        color: #0d0d0d;

        font-size: 16px;
        margin: 5px;
        width: 85%;
        border: 2px solid #f6f6f6;
        @extend %input;
        @include input_animation();

    }

    input:focus {
        background-color: $white;
        border-bottom: 2px solid $formsbtncolr;
    }

    input:placeholder {
        color: $bg-color-3;
    }

    .icon:before {
        transform: scale(1) rotate(45deg);
    }

    .icon:after {
        transform: scale(1) rotate(-45deg);
    }

    .fadeInDown {
        -webkit-animation-name: fadeInDown;
        animation-name: fadeInDown;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }

    @-webkit-keyframes fadeInDown {
        0% {
            opacity: 0;
            -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
        }

        100% {
            opacity: 1;
            -webkit-transform: none;
            transform: none;
        }
    }

    @keyframes fadeInDown {
        0% {
            opacity: 0;
            -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
        }

        100% {
            opacity: 1;
            -webkit-transform: none;
            transform: none;
        }
    }

    @-webkit-keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @-moz-keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    .fadeIn {
        opacity: 0;
        -webkit-animation: fadeIn ease-in 1;
        -moz-animation: fadeIn ease-in 1;
        animation: fadeIn ease-in 1;

        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards;

        -webkit-animation-duration: 1s;
        -moz-animation-duration: 1s;
        animation-duration: 1s;
    }

    .fadeIn.first {
        -webkit-animation-delay: 0.4s;
        -moz-animation-delay: 0.4s;
        animation-delay: 0.4s;
    }

    .fadeIn.second {
        -webkit-animation-delay: 0.6s;
        -moz-animation-delay: 0.6s;
        animation-delay: 0.6s;
    }

    .fadeIn.third {
        -webkit-animation-delay: 0.8s;
        -moz-animation-delay: 0.8s;
        animation-delay: 0.8s;
    }

    .fadeIn.fourth {
        -webkit-animation-delay: 1s;
        -moz-animation-delay: 1s;
        animation-delay: 1s;
    }

    .underlineHover:after {
        display: block;
        left: 0;
        bottom: -10px;
        width: 0;
        height: 2px;
        background-color: #56baed;
        content: "";
        transition: width 0.2s;
    }

    .underlineHover:hover {
        color: #0d0d0d;
    }

    .underlineHover:hover:after {
        width: 100%;
    }

    .alert-danger {

        padding: 7px;
        // margin: 1rem;
        border-radius: 9px;
    }
}